import React from 'react';
import './style.css';
import Header from '../common/header';

class Portfolio extends React.Component {

    constructor(props) {
        super();
    }
  
    render() {
      return (
        <mp>   
            <Header banner='portfolio' />
            <h1>PORTFOLIO</h1>
        </mp>
      );
    }
  }
  
  export default Portfolio;
  